/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-alert */
import React, { useRef } from 'react';
import { navigate } from 'gatsby';
import { Button } from '@allthingswww/client-act-shared';
import ReactToPrint from 'react-to-print';

import _ from 'lodash';
import styled from 'styled-components';
import Layout from '../components/Layout';

const MainContainer = styled.div`
  margin: 0 auto;
  max-width: 45em;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: space-between;
`;

const BoldDiv = styled.div`
  font-weight: 600;
`;

const PrintBtnArea = styled.div`
padding: 1rem;
`;

const Currency = new Intl.NumberFormat('en-us', { style: 'currency', currency: 'USD' });

// Using class component because ReactToPrint only works with class components
// eslint-disable-next-line react/prefer-stateless-function
class PrintPurchasesPage extends React.Component {
  render() {
    const purchaseData = _.get(this.props, 'location.state', null);

    if (!purchaseData) {
      if (typeof window !== 'undefined') {
        alert('No purchase data found');
        navigate('/members/my-purchases');
      }
      return (
        <MainContainer>
          <p>No purchase data available</p>
        </MainContainer>
      );
    }

    const {
      _id,
      eventName,
      eventId,
      transactionId,
      transactionStatus,
      transactionTimestamp,
      transactionAmount,
      eventItemsBought,
    } = purchaseData;

    return (
      <MainContainer>
        <Row>
          <BoldDiv>
            Transaction ID:
          </BoldDiv>
          <div>
            {transactionId}
          </div>
        </Row>
        <Row>
          <BoldDiv>
            Purchase ID:
          </BoldDiv>
          <div>
            {_id}
          </div>
        </Row>
        <Row>
          <BoldDiv>
            Transaction Status:
          </BoldDiv>
          <div>
            {transactionStatus}
          </div>
        </Row>
        <Row>
          <BoldDiv>
            Transaction Time stamp:
          </BoldDiv>
          <div>
            {transactionTimestamp}
          </div>
        </Row>
        <Row>
          <BoldDiv>
            Transaction Amount:
          </BoldDiv>
          <div>
            {Currency.format(transactionAmount / 100)}
          </div>
        </Row>
        --------------------------------------------------
        {
          eventId && (
            <div>
              <Row>
                <BoldDiv>
                  Event ID:
                </BoldDiv>
                <div>
                  {eventId}
                </div>
              </Row>
              <Row>
                <BoldDiv>
                  Event Name:
                </BoldDiv>
                <div>
                  {eventName}
                </div>
              </Row>
              {eventItemsBought.length > 1 && (
                eventItemsBought.map((eventItem) => (
                  <Row>
                    {eventItem.name}
                  </Row>
                ))
              )}
            </div>
          )
        }
      </MainContainer>
    );
  }
}

const PrintPurchasePageWithPrint = (props) => {
  const componentRef = useRef();
  return (
    <Layout title="Member Edit" subtitle="">
      <MainContainer>
        <PrintPurchasesPage ref={componentRef} {...props} />
        <PrintBtnArea>
          <ReactToPrint
            trigger={() => <Button.Button variant>Print code</Button.Button>}
            content={() => componentRef.current}
            pageStyle="@page{ margin: 2.5cm; }"
          />
        </PrintBtnArea>
      </MainContainer>
    </Layout>
  );
};

export default PrintPurchasePageWithPrint;
